import React from 'react';
import PropTypes from 'prop-types';
import {Tooltip} from 'antd';
import {useHistory} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch, faSync, faLink} from '@fortawesome/pro-regular-svg-icons';

import StringDiff from '../../../../common/stringDiff/StringDiff.jsx';
import CopyToClipboard from '../UrlActions/CopyToClipboard.jsx';
import ExternalLink from '../UrlActions/ExternalLink.jsx';
import {formatDuration} from '../ConversionResults.jsx';
import {CONVERTER_TYPE, STAT_TYPES} from '../types.js';

import classes from '../ConversionResults.module.less';


export default function SingleLinkStat({data}) {
    const {
        before,
        converted,
        converter,
        conversionDuration,
        expanded,
        expansionDuration,
        shortened,
        shorteningDuration,
        history,
    } = data;

    return <div className={classes.singleItem}>
        <HistoryBox history={history}/>
        <LinkBox>{before}</LinkBox>
        {expanded ? <>
            <OperationBox icon={faSearch} label="Expansion" duration={expansionDuration}/>
            <LinkBox compareWith={before}>{expanded}</LinkBox>
        </> : null}
        {converted ? <>
            <OperationBox icon={faSync} label="Conversion" converter={converter} duration={conversionDuration}/>
            <LinkBox compareWith={expanded ? expanded : before}>{converted}</LinkBox>
        </> : null}
        {shortened ? <>
            <OperationBox icon={faLink} label="Shortening" duration={shorteningDuration}/>
            <LinkBox>{shortened}</LinkBox>
        </> : null}
    </div>;
}

SingleLinkStat.propTypes = {
    data: STAT_TYPES,
};


function LinkBox({children, compareWith}) {
    return <div className={classes.urlBoxContainer}>
        <div className={classes.urlBox}>
            {compareWith
                ? <StringDiff hideRemoved after={children} before={compareWith}/>
                : children
            }
        </div>
        <div className={classes.urlActions}>
            <CopyToClipboard text={children}/>
            <ExternalLink url={children}/>
        </div>
    </div>;
}

LinkBox.propTypes = {
    children: PropTypes.string.isRequired,
    compareWith: PropTypes.string,
};


function OperationBox({icon, label, duration, converter}) {
    const durationString = formatDuration(duration);

    return <div className={classes.operationBox}>
        <div className={classes.operationIcon}>
            <FontAwesomeIcon icon={icon}/>
        </div>
        <div className={classes.operationLabel}>
            {label}
        </div>
        <div className={classes.metadata}>
            {converter && <ConverterLink converter={converter}/>}
            {duration && <div className={classes.duration}>
                <Tooltip title={`${duration} ms`}>
                    {durationString}
                </Tooltip>
            </div>}
        </div>
    </div>;
}

OperationBox.propTypes = {
    icon: PropTypes.object.isRequired,
    label: PropTypes.node.isRequired,
    duration: PropTypes.number,
    converter: CONVERTER_TYPE,
};


function ConverterLink({converter}) {
    const history = useHistory();

    const onConverterClick = () => {
        history.push(`/vendor/${converter.slug}`);
    };

    return <div className={classes.operatorName} onClick={onConverterClick}>
        {converter.name}
    </div>;
}

ConverterLink.propTypes = {
    converter: CONVERTER_TYPE,
};


/* History Box */


function HistoryBox({history}) {
    if(!history) {
        return null;
    }

    return <>
        {history.map((historyItem, index) => {
            const {error, converter, data} = historyItem;

            if (error === 'MISSING_PARAMS') {
                return <MissingParamsWarning params={data.missingParams} converter={converter} key={index}/>;
            }
            return <UnknownError error={data.error} key={index} converter={converter}/>;
        })}
    </>;
}

HistoryBox.propTypes = {
    history: PropTypes.arrayOf(PropTypes.shape({
        error: PropTypes.string.isRequired,
        converter: CONVERTER_TYPE.isRequired,
        data: PropTypes.object.isRequired,
    })),
};


function MissingParamsWarning({converter, params}) {
    return <div className={classes.historyBox}>
        The converter <ConverterLink converter={converter}/> couldn't work because you have missing parameters in your
        configuration: <b>{params.join(', ')}</b>.
    </div>;
}

MissingParamsWarning.propTypes = {
    converter: CONVERTER_TYPE,
    params: PropTypes.arrayOf(PropTypes.string).isRequired,
};


function UnknownError({converter, error}) {
    return <div className={classes.historyBox}>
        The converter <ConverterLink converter={converter}/> Raised an error: <i>{JSON.stringify(error)}</i>.
    </div>;
}

UnknownError.propTypes = {
    converter: CONVERTER_TYPE,
    error: PropTypes.any,
};