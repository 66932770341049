import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button} from 'antd';

import {logoutUser} from '../../../store/ducks/user/operations.js';

import classes from './LogoutButton.module.less';


function LogoutButton({dispatch, ...rest}) {
    const onLogoutClick = () => {
        dispatch(logoutUser());
    };

    return <Button type="link" onClick={onLogoutClick} {...rest} className={classes.button}>
        Logout
    </Button>;
}

LogoutButton.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

export default connect(null, null)(LogoutButton);

