import React from 'react';

import Logo from '../../../assets/logo.svg';
import LogoLabel from '../../../assets/logo_label.svg';

import classes from './GuestHeader.module.less';


export default function GuestHeader() {
    return <div className={classes.guestHeader}>
        <div className={classes.logo}>
            <Logo/>
        </div>
        <div className={classes.label}>
            <LogoLabel/>
        </div>
    </div>
}