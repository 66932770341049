import axios from 'axios';

import firebaseService from '../firebaseService.js';
import * as convertAPI from './convert.js';
import * as usersAPI from './users.js';
import * as vendorsAPI from './vendors.js';
import * as settingsAPI from './settings.js';


export const API_URL =
    ENV_MODE === 'development'
        ? 'http://localhost:5000/affendor-cc600/us-central1/app'
        : 'https://us-central1-affendor-cc600.cloudfunctions.net/app';

export const API = axios.create({
    baseURL: `${API_URL}/api`,
});

API.interceptors.request.use(async options => {
    const userToken = firebaseService.auth().currentUser && await firebaseService.auth().currentUser.getIdToken();
    options.headers['Authorization'] = `Bearer ${userToken}`;

    return options;
});

export {convertAPI, usersAPI, vendorsAPI, settingsAPI};