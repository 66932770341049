import React from 'react';
import {hot} from 'react-hot-loader/root';
import {Provider} from 'react-redux';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

import store from './store';
import MainLayout from "./layouts/mainLayout/MainLayout.jsx";


momentDurationFormatSetup(moment);

function App() {
    return <Provider store={store}>
        <MainLayout/>
    </Provider>;
}

export default hot(App);
