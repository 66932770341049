import React from 'react';
import PropTypes from 'prop-types';
import {diffWords} from 'diff';

import classes from './StringDiff.module.less';


export default function StringDiff({before, after, hideRemoved}) {
    const difference = diffWords(before, after);
    return <>
        {difference.map((part, index) => {
            if (hideRemoved && part.removed) {
                return null;
            }

            const className = part.added ? classes.added :
                part.removed ? classes.removed : '';

            return <span className={className} key={JSON.stringify({part, index})}>{part.value}</span>;
        })}
    </>;
}

StringDiff.propTypes = {
    before: PropTypes.string.isRequired,
    after: PropTypes.string.isRequired,
    hideRemoved: PropTypes.bool,
};

StringDiff.defaultProps = {
    hideRemoved: false,
};