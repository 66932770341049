import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import GuestHeader from '../../components/authentications/guestHeader/GuestHeader.jsx';
import Login from '../../components/authentications/forms/login/Login.jsx';
import Register from '../../components/authentications/forms/register/Register.jsx';
import AlreadyLoggedInAlert from './alreadyLoggedInAlert/AlreadyLoggedInAlert.jsx';


function GuestLayout({registerPage, loggedIn}) {
    return <div>
        <GuestHeader/>
        {!loggedIn ? <>
            {registerPage ? <Register/> : <Login/>}
        </> : <>
            <AlreadyLoggedInAlert/>
        </>}
    </div>;
}

GuestLayout.propTypes = {
    registerPage: PropTypes.bool,
    loggedIn: PropTypes.bool.isRequired,
};


function mapStateToProps(state) {
    return {
        loggedIn: state.user.loggedIn,
    };
}

export default connect(mapStateToProps)(GuestLayout);
