import {setMobileState} from './actions.js';
import {mobileMediaQuery} from '../../../theme/utils/responsive.less';


export function recalculateMobileState() {
    return (dispatch, getState) => {
        const isMobile = window.matchMedia(mobileMediaQuery).matches;
        const currentMobileState = getState().layout.isMobile;

        if(isMobile !== currentMobileState) {
            dispatch(setMobileState(isMobile));
        }
    };
}