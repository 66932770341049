import React from 'react';
import PropTypes from 'prop-types';
import {message} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy} from '@fortawesome/pro-regular-svg-icons';
import copy from 'copy-to-clipboard';

import classes from '../ConversionResults.module.less';


export default function CopyToClipboard({text}) {
    const onClick = () => {
        copy(text);
        message.info('Successfully copied to clipboard.');
    };

    return <div className={classes.action} onClick={onClick}>
        <FontAwesomeIcon icon={faCopy}/>
    </div>;
}

CopyToClipboard.propTypes = {
    text: PropTypes.string.isRequired,
};