import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLink} from '@fortawesome/pro-regular-svg-icons';

import classes from '../ConversionResults.module.less';


export default function ExternalLink({url}) {
    const onClick = () => {
        window.open(url, '_blank');
    };

    return <div className={classes.action} onClick={onClick}>
        <FontAwesomeIcon icon={faExternalLink}/>
    </div>;
}

ExternalLink.propTypes = {
    url: PropTypes.string.isRequired,
};