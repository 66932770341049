exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DashboardHeader-module--header___12OH_ {\n  display: flex;\n  background: #0f1b24;\n  color: white;\n  height: 4rem;\n  padding: 0 1.5rem;\n  position: relative;\n}\n.DashboardHeader-module--header___12OH_ .DashboardHeader-module--logo___3yOkq {\n  display: flex;\n  align-items: center;\n  margin-inline-end: 2em;\n  cursor: pointer;\n  user-select: none;\n  justify-content: center;\n  flex-grow: 0;\n  transition: flex-grow 1s cubic-bezier(0, 0.99, 0, 1);\n}\n.DashboardHeader-module--header___12OH_ .DashboardHeader-module--logo___3yOkq .DashboardHeader-module--logoItself___3j7-F {\n  height: 2rem;\n}\n.DashboardHeader-module--header___12OH_ .DashboardHeader-module--logo___3yOkq .DashboardHeader-module--logoLabel___2sTfv {\n  height: 1.5rem;\n  margin-inline-start: 1em;\n  margin-bottom: 0.2em;\n}\n.DashboardHeader-module--header___12OH_ .DashboardHeader-module--logo___3yOkq .DashboardHeader-module--logoLabel___2sTfv path {\n  fill: currentColor;\n}\n@media (max-width: 768px) {\n  .DashboardHeader-module--header___12OH_ .DashboardHeader-module--logo___3yOkq {\n    margin: 0 4rem;\n    flex-grow: 1;\n    width: 0;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"mobileMediaQuery": "(max-width: 768px)",
	"header": "DashboardHeader-module--header___12OH_",
	"logo": "DashboardHeader-module--logo___3yOkq",
	"logoItself": "DashboardHeader-module--logoItself___3j7-F",
	"logoLabel": "DashboardHeader-module--logoLabel___2sTfv"
};