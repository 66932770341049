exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".DashboardLayout-module--mainLayout___3BU8s {\n  height: 100%;\n}\n.DashboardLayout-module--innerLayout___3lpzS {\n  flex-grow: 1;\n  position: relative;\n}\n.DashboardLayout-module--contentLayout___bPgjt {\n  overflow-y: auto;\n  padding: 2em;\n  padding-top: 0;\n  background: #f0f2f4;\n}\n.DashboardLayout-module--contentLayout___bPgjt::-webkit-scrollbar {\n  width: 0.75em;\n  background: transparent;\n}\n.DashboardLayout-module--contentLayout___bPgjt::-webkit-scrollbar-thumb {\n  background: rgba(0, 0, 0, 0.1);\n  border-radius: 2em;\n  border: 3px solid transparent;\n  background-clip: content-box;\n}\n@media (max-width: 768px) {\n  .DashboardLayout-module--contentLayout___bPgjt {\n    padding: 0;\n  }\n}\n.DashboardLayout-module--breadcrumbs___2HVlK {\n  margin: 1em 0;\n}\n@media (max-width: 768px) {\n  .DashboardLayout-module--breadcrumbs___2HVlK {\n    padding: 0 1em;\n  }\n}\n.DashboardLayout-module--pageContent___2cAzE {\n  background: white;\n  padding: 1.5em;\n  margin: 0;\n  min-height: auto;\n}\n", ""]);
// Exports
exports.locals = {
	"mobileMediaQuery": "(max-width: 768px)",
	"mainLayout": "DashboardLayout-module--mainLayout___3BU8s",
	"innerLayout": "DashboardLayout-module--innerLayout___3lpzS",
	"contentLayout": "DashboardLayout-module--contentLayout___bPgjt",
	"breadcrumbs": "DashboardLayout-module--breadcrumbs___2HVlK",
	"pageContent": "DashboardLayout-module--pageContent___2cAzE"
};