exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".UserMenu-module--header___uOidE {\n  display: flex;\n  background: #0f1b24;\n  color: white;\n  height: 4rem;\n  padding: 0 1.5rem;\n  position: relative;\n}\n.UserMenu-module--header___uOidE .UserMenu-module--logo___qmpvE {\n  display: flex;\n  align-items: center;\n  margin-inline-end: 2em;\n  cursor: pointer;\n  user-select: none;\n  justify-content: center;\n  flex-grow: 0;\n  transition: flex-grow 1s cubic-bezier(0, 0.99, 0, 1);\n}\n.UserMenu-module--header___uOidE .UserMenu-module--logo___qmpvE .UserMenu-module--logoItself___3e7WQ {\n  height: 2rem;\n}\n.UserMenu-module--header___uOidE .UserMenu-module--logo___qmpvE .UserMenu-module--logoLabel___ykAr7 {\n  height: 1.5rem;\n  margin-inline-start: 1em;\n  margin-bottom: 0.2em;\n}\n.UserMenu-module--header___uOidE .UserMenu-module--logo___qmpvE .UserMenu-module--logoLabel___ykAr7 path {\n  fill: currentColor;\n}\n@media (max-width: 768px) {\n  .UserMenu-module--header___uOidE .UserMenu-module--logo___qmpvE {\n    margin: 0 4rem;\n    flex-grow: 1;\n    width: 0;\n  }\n}\n.UserMenu-module--menuButton___3ptzw {\n  width: 4rem;\n  height: 100%;\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.UserMenu-module--menuPopover___3rPX4 {\n  margin-left: -16px;\n}\n.UserMenu-module--headerMenu___36vR3 {\n  display: flex;\n  flex-grow: 1;\n  justify-content: flex-end;\n  color: inherit;\n  background: transparent;\n}\n.UserMenu-module--headerMenu___36vR3 .UserMenu-module--menuItem___1tman {\n  display: flex;\n  align-items: center;\n}\n.UserMenu-module--headerMenu___36vR3 .UserMenu-module--menuItem___1tman.UserMenu-module--noPadding___2iA2E {\n  padding: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"isRTL": "false",
	"startDirection": "left",
	"endDirection": "right",
	"mobileMediaQuery": "(max-width: 768px)",
	"header": "UserMenu-module--header___uOidE",
	"logo": "UserMenu-module--logo___qmpvE",
	"logoItself": "UserMenu-module--logoItself___3e7WQ",
	"logoLabel": "UserMenu-module--logoLabel___ykAr7",
	"menuButton": "UserMenu-module--menuButton___3ptzw",
	"menuPopover": "UserMenu-module--menuPopover___3rPX4",
	"headerMenu": "UserMenu-module--headerMenu___36vR3",
	"menuItem": "UserMenu-module--menuItem___1tman",
	"noPadding": "UserMenu-module--noPadding___2iA2E"
};