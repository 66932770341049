import React from 'react';
import {Menu, Popover} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUserAlt as faUser} from '@fortawesome/pro-solid-svg-icons';

import classes from './UserMenu.module.less';
import LogoutButton from '../../../../components/authentications/logoutButton/LogoutButton.jsx';


export default function UserMenu() {

    return <Popover
        content={<UserMenuContents/>}
        placement="bottomRight"
        overlayClassName={classes.menuPopover}
    >
        <div className={classes.menuButton}>
            <FontAwesomeIcon icon={faUser}/>
        </div>
    </Popover>
}

function UserMenuContents() {
    return <Menu
        theme="dark"
        mode="horizontal"
        defaultSelectedKeys={['2']}
        className={classes.headerMenu}
    >
        <Menu.Item key="3" className={`${classes.menuItem} ${classes.noPadding}`}><LogoutButton/></Menu.Item>
    </Menu>;
}