import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Spin, message, Tooltip} from 'antd';
import _ from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinnerThird} from '@fortawesome/pro-regular-svg-icons';
import {faExclamationCircle} from '@fortawesome/pro-regular-svg-icons';
import {faSync} from '@fortawesome/pro-solid-svg-icons';

import classes from './FetchWrapper.module.less';


export const LoadingIndicator = () => {
    return <div className={classes.spinnerIndicator}>
        <FontAwesomeIcon icon={faSpinnerThird} spin size="lg"/>
    </div>;
};


const ErrorIndicator = ({content, retry}) => {
    const tooltipContent = <div className={classes.tooltipMessage}>
        <div className={classes.messageContent}>{content}</div>
        {retry && <div className={classes.retry} onClick={retry}>
            <FontAwesomeIcon icon={faSync}/>
            <div className={classes.retryLabel}>retry</div>
        </div>}
    </div>;

    return <div className={classes.spinnerIndicator}>
        <Tooltip title={tooltipContent}>
            <FontAwesomeIcon icon={faExclamationCircle} size="lg"/>
        </Tooltip>
    </div>;
};

ErrorIndicator.propTypes = {
    content: PropTypes.node,
    retry: PropTypes.func,
};


export default function FetchWrapper({fetcher, children, trigger}) {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    async function performFetch() {
        setErrorMessage(null);
        setIsLoading(true);
        try {
            await fetcher();
        } catch (e) {
            message.error(e.message);
            setErrorMessage(e.message);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        performFetch();
    }, trigger);

    const indicator = errorMessage
        ? <ErrorIndicator content={errorMessage} retry={performFetch}/>
        : <LoadingIndicator/>;

    return <Spin indicator={indicator} spinning={isLoading || !_.isEmpty(errorMessage)}>
        {children}
    </Spin>;
}

FetchWrapper.propTypes = {
    fetcher: PropTypes.func.isRequired,
    children: PropTypes.node.isRequired,
    trigger: PropTypes.array,
};

FetchWrapper.defaultProps = {
    trigger: [],
};
