import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinnerThird} from '@fortawesome/pro-regular-svg-icons/faSpinnerThird.js';

import classes from './LoadingWall.module.less';


export default function LoadingWall({isLoading}) {
    return <div className={`${classes.loadingWall} ${isLoading ? '' : classes.hidden}`}>
        <FontAwesomeIcon icon={faSpinnerThird} spin/>
    </div>
}

LoadingWall.propTypes = {
    isLoading: PropTypes.bool.isRequired,
};