import React from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';
import {Layout} from 'antd';

import AffendorLogo from '../../../assets/logo.svg';
import AffendorLogoLabel from '../../../assets/logo_label.svg';
import MenuButton from './menuButton/MenuButton.jsx';
import UserMenu from './userMenu/UserMenu.jsx';

import classes from './DashboardHeader.module.less';

const {Header} = Layout;


export default function DashboardHeader({toggleMenuState, isMenuOpen}) {
    const history = useHistory();

    const onLogoClick = () => history.push('/');

    return <Header className={classes.header}>
        <MenuButton isMenuOpen={isMenuOpen} onClick={toggleMenuState}/>
        <div className={classes.logo} onClick={onLogoClick}>
            <AffendorLogo className={classes.logoItself}/>
            <AffendorLogoLabel className={classes.logoLabel}/>
        </div>
        <UserMenu/>
    </Header>;
}

DashboardHeader.propTypes = {
    toggleMenuState: PropTypes.func.isRequired,
    isMenuOpen: PropTypes.bool.isRequired,
};
