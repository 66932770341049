import React from 'react';
import PropTypes from 'prop-types';

import classes from './MenuButton.module.less';


export default function MenuButton({isMenuOpen, ...rest}) {
    const classNames = [
        classes.menuButton,
        isMenuOpen ? classes.menuOpen : '',
        rest.className ? rest.className : '',
    ];
    return <div className={classNames.join(' ')} {...rest}>
        <div className={`${classes.bar} ${classes.barTop}`}/>
        <div className={`${classes.bar} ${classes.barMiddle}`}/>
        <div className={`${classes.bar} ${classes.barBottom}`}/>
    </div>;
}

MenuButton.propTypes = {
    isMenuOpen: PropTypes.bool.isRequired,
};
