import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import AppRoutes from './AppRoutes.jsx';
import {authStateChanged} from '../../store/ducks/user/actions.js';
import {recalculateMobileState} from '../../store/ducks/layout/operations.js';
import firebaseService from '../../services/firebaseService.js';
import LoadingWall from './LoadingWall/LoadingWall.jsx';
import RedirectToLink from './RedirectToLink.jsx';


function MainLayout({dispatch}) {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        firebaseService.auth().onAuthStateChanged(user => {
            dispatch(authStateChanged(user));
            setIsLoading(false);
        });
    }, []);

    useEffect(() => {
        function onResize() {
            dispatch(recalculateMobileState());
        }

        onResize();
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, []);


    return <BrowserRouter>
        <Switch>
            <Route exact path="/go/:id" component={RedirectToLink}/>
            <Route path="/" render={() => <>
                <LoadingWall isLoading={isLoading}/>
                {!isLoading ? <AppRoutes/> : null}
            </>}/>
        </Switch>
    </BrowserRouter>;
}

MainLayout.propTypes = {
    dispatch: PropTypes.func.isRequired,
};

export default connect(null, null)(MainLayout);