import React, {useState} from 'react';
import {Route, useLocation} from 'react-router-dom';
import {Layout, Breadcrumb} from 'antd';
import _ from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/pro-light-svg-icons/faAngleRight.js';

import classes from './DashboardLayout.module.less';
import DashboardSidebar from './dashboardSidebar/DashboardSidebar.jsx';
import DashboardHeader from './header/DashboardHeader.jsx';
import ConvertParagraph from '../../components/conversions/convertParagraph/ConvertParagraph.jsx';
import VendorView from '../../components/vendors/VendorView/VendorView.jsx';
import DashboardHome from '../../components/dashboard/dashboardHome/DashboardHome.jsx';
import SettingsView from '../../components/settings/SettingsView/SettingsView.jsx';

const {Content} = Layout;


export default function DashboardLayout() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    return <Layout className={classes.mainLayout}>
        <DashboardHeader isMenuOpen={isMenuOpen} toggleMenuState={() => setIsMenuOpen(!isMenuOpen)}/>
        <Layout className={classes.innerLayout}>
            <DashboardSidebar isMenuOpen={isMenuOpen} closeMenu={() => setIsMenuOpen(false)}/>
            <DashboardContent/>
        </Layout>
    </Layout>;
}


const DashboardContent = () => {
    const location = useLocation();
    const pathnameParts = location.pathname.split('/').filter(i => i);

    return <Layout className={classes.contentLayout}>
        <Breadcrumb className={classes.breadcrumbs} separator={<FontAwesomeIcon icon={faAngleRight}/>}>
            {pathnameParts.map(part => <Breadcrumb.Item key={part}>{_.words(part).map(_.upperFirst).join(' ')}</Breadcrumb.Item>)}
        </Breadcrumb>
        <Content className={classes.pageContent}>
            <Route exact path="/" component={DashboardHome}/>
            <Route exact path="/convert/paragraph" component={ConvertParagraph}/>
            <Route exact path="/vendor/:slug" component={VendorView}/>
            <Route exact path="/settings" component={SettingsView}/>
        </Content>
    </Layout>;
};
