import React from 'react';
import {Route, Switch} from 'react-router-dom';

import PrivateRoute from '../../components/authentications/privateRoute/PrivateRoute.jsx';
import DashboardLayout from '../dashboardLayout/DashboardLayout.jsx';
import GuestLayout from '../guestLayout/GuestLayout.jsx';


export default function AppRoutes() {
    return <Switch>
        <Route exact path="/login" component={GuestLayout}/>
        <Route exact path="/register" render={props => <GuestLayout {...props} registerPage/>}/>
        <PrivateRoute path="/" component={DashboardLayout}/>
    </Switch>;
}