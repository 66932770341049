import types from './types.js';


export const userLogin = user => ({
    type: types.USER_LOGIN,
    user,
});
export const userLogout = () => ({
    type: types.USER_LOGOUT,
});

export const authStateChanged = user => ({
    type: types.AUTH_STATE_CHANGED,
    user,
});
