import initialState from './initialState.js';
import types from './types.js';

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case types.SET_CURRENT_VENDOR:
        return {
            ...state,
            currentVendor: action.vendor,
        };
    default:
        return state;
    }

}
