import React from 'react';
import {Form, Input} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import classes from './AuthForm.module.less';


export const renderFormItem = (form, field) => {
    const {isFieldTouched, getFieldError, getFieldDecorator} = form;

    const fieldError = isFieldTouched(field.name) && getFieldError(field.name);
    return <Form.Item validateStatus={fieldError ? 'error' : ''} help={fieldError || ''} key={field.name}>
        {getFieldDecorator(field.name, {
            rules: field.rules,
        })(
            <Input
                prefix={<FontAwesomeIcon icon={field.icon} className={classes.inputIcon}/>}
                placeholder={field.placeholder}
                type={field.type || 'text'}
            />,
        )}
    </Form.Item>;
};

export function hasErrors(fieldsError) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}