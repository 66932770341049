import React from 'react';
import PropTypes from 'prop-types';
import {Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';


function PrivateRoute({loggedIn, component: RouteComponent, ...rest}) {
    return <Route
        {...rest}
        render={routeProps => (
            loggedIn
                ? <RouteComponent {...routeProps}/>
                : <Redirect to="/login"/>
        )}
    />;
}

PrivateRoute.propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    component: PropTypes.elementType.isRequired,
};

export default connect(state => ({
    loggedIn: state.user.loggedIn,
}))(PrivateRoute);

