import React from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';
import {Form, Button, Checkbox, message} from 'antd';
import {connect} from 'react-redux';
import {faUser} from '@fortawesome/pro-regular-svg-icons/faUser.js';
import {faLockAlt} from '@fortawesome/pro-regular-svg-icons/faLockAlt.js';

import {loginUser, requestEmailVerification} from '../../../../store/ducks/user/operations.js';
import {EmailNotVerifiedException} from '../../../../store/ducks/user/exceptions.js';
import {renderFormItem, hasErrors} from '../utils.js';

import formClasses from '../AuthForm.module.less';


const RESEND_VERIFICATION_EMAIL_KEY = 'resendEmail';

const FORM_FIELDS = [
    {
        name: 'email',
        rules: [{required: true, message: 'What\'s your email address?'}],
        placeholder: 'Email Address',
        type: 'email',
        icon: faUser,
    },
    {
        name: 'password',
        rules: [{required: true, message: 'Your password cannot be empty.'}],
        placeholder: 'Password',
        type: 'password',
        icon: faLockAlt,
    },
];


class LoginForm extends React.Component {
    static propTypes = {
        form: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            loggingIn: false,
        };

        this.handleSubmit = ::this.handleSubmit;
        this.performLogin = ::this.performLogin;
    }

    componentDidMount() {
        this.props.form.validateFields();
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                message.error(err);
            } else {
                this.performLogin(values);
            }
        });
    }

    handleEmailNotVerified({email, password}) {
        message.warning({
            key: RESEND_VERIFICATION_EMAIL_KEY,
            content: <>
                Your email address has not been verified. Please check your inbox or
                <Button
                    type="link"
                    onClick={() => this.sendEmailVerificationLink(email, password)}>
                    resend the verification link
                </Button>
            </>
        });
    }

    async sendEmailVerificationLink(email, password) {
        try {
            message.loading({
                content: `Sending a new verification link to ${email}...`,
                key: RESEND_VERIFICATION_EMAIL_KEY
            });
            await this.props.dispatch(requestEmailVerification(email, password));
            message.success({content: 'Check your inbox.', key: RESEND_VERIFICATION_EMAIL_KEY});
        } catch (e) {
            message.error({content: e.message, key: RESEND_VERIFICATION_EMAIL_KEY});
        }
    }

    async performLogin({email, password, remember}) {
        this.setState({loggingIn: true});

        try {
            await this.props.dispatch(loginUser(email, password, remember));
            this.props.history.push('/');
        } catch (e) {
            if (e instanceof EmailNotVerifiedException) {
                this.handleEmailNotVerified(e.userCredentials);
            } else {
                message.error(e.message);
            }
            this.setState({loggingIn: false});
        }
    }

    render() {
        const {getFieldDecorator, getFieldsError} = this.props.form;

        return <Form onSubmit={this.handleSubmit} className={formClasses.form}>
            {FORM_FIELDS.map(field => renderFormItem(this.props.form, field))}

            <Form.Item>
                {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: true,
                })(<Checkbox>Remember me</Checkbox>)}

                <a className={formClasses.forgot} href="">Forgot password</a>

                <Button
                    type="primary"
                    htmlType="submit"
                    className={formClasses.submitButton}
                    disabled={hasErrors(getFieldsError())}
                    loading={this.state.loggingIn}
                >
                    Log in
                </Button>
                or <Link to="/register">Sign up now</Link>
            </Form.Item>
        </Form>;
    }
}

const Login = Form.create()(withRouter(connect(
    null,
    null,
)(LoginForm)));

export default Login;