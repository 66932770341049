exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".VendorView-module--vendorParamContainer___itHJ7 {\n  display: flex;\n  flex-direction: column;\n  margin-top: 1.875em;\n}\n.VendorView-module--vendorParam___1x6dv {\n  padding: 1.25em 1.5em;\n  background: rgba(141, 90, 32, 0.05);\n  border-radius: 1em;\n  max-width: 40em;\n}\n.VendorView-module--vendorParam___1x6dv + .VendorView-module--vendorParam___1x6dv {\n  margin-top: 1.5em;\n}\n@media (max-width: 768px) {\n  .VendorView-module--vendorParam___1x6dv {\n    padding: 1em 1.25em;\n  }\n}\n.VendorView-module--vendorParam___1x6dv .VendorView-module--input___2kMf5 {\n  padding: 1em;\n  border: 0;\n  background: rgba(141, 90, 32, 0.05);\n  font: inherit;\n  font-family: Consolas, monospace;\n  width: 100%;\n  transition: 0.2s;\n  border-radius: 0.5em;\n  margin-bottom: 0.25em;\n}\n.VendorView-module--vendorParam___1x6dv .VendorView-module--input___2kMf5::placeholder {\n  color: rgba(141, 90, 32, 0.6);\n}\n.VendorView-module--vendorParam___1x6dv .VendorView-module--input___2kMf5:focus {\n  outline: 0;\n  box-shadow: inset 0 0 0 2px rgba(141, 90, 32, 0.5);\n}\n.VendorView-module--vendorParam___1x6dv .VendorView-module--input___2kMf5.VendorView-module--inputEmptyError___q1ueV {\n  box-shadow: inset 0 0 0 2px rgba(217, 87, 83, 0.75);\n  background: rgba(126, 61, 59, 0.05);\n}\n.VendorView-module--vendorParam___1x6dv p {\n  margin-bottom: 0;\n}\n.VendorView-module--saveBtn___101Az {\n  margin-top: 1em;\n}\n.VendorView-module--emptyError___10FTA {\n  color: #ab4a47;\n  font-size: 0.875em;\n  font-weight: 500;\n}\n", ""]);
// Exports
exports.locals = {
	"mobileMediaQuery": "(max-width: 768px)",
	"vendorParamContainer": "VendorView-module--vendorParamContainer___itHJ7",
	"vendorParam": "VendorView-module--vendorParam___1x6dv",
	"input": "VendorView-module--input___2kMf5",
	"inputEmptyError": "VendorView-module--inputEmptyError___q1ueV",
	"saveBtn": "VendorView-module--saveBtn___101Az",
	"emptyError": "VendorView-module--emptyError___10FTA"
};