import {combineReducers} from 'redux';

import vendorsReducer from './vendors';
import userReducer from './user';
import layoutReducer from './layout';


export default combineReducers({
    vendors: vendorsReducer,
    user: userReducer,
    layout: layoutReducer,
});
