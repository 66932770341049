import React from 'react';
import {Input, Button, message, Switch, Spin} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDoubleRight} from '@fortawesome/pro-duotone-svg-icons';
import ConversionResults from './conversionResults/ConversionResults.jsx';

import {convertAPI} from '../../../services/api';
import Card from '../../layout/card/Card.jsx';

import classes from './ConvertParagraph.module.less';

const {TextArea} = Input;


const MESSAGE_KEY = 'CONVERT_PARAGRAPH';


export default class ConvertParagraph extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            displaysResult: false,
            originalContent: '',
            conversionResult: '',
            deep: true,
            shorten: true,
            isLoading: false,
        };

        this.performConversion = ::this.performConversion;
        this.onPressEnter = ::this.onPressEnter;
    }

    setOriginalContent = value => this.setState({originalContent: value});
    toggleDeep = () => this.setState({deep: !this.state.deep});
    toggleShorten = () => this.setState({shorten: !this.state.shorten});

    async performConversion() {
        const {originalContent, deep, shorten} = this.state;

        this.setState({isLoading: true});
        try {
            const result = await convertAPI.convertParagraph(originalContent, {deep, shorten});
            this.setState({
                conversionResult: result.data,
                displaysResult: true,
            });
            message.success({content: 'Successfully converted.', key: MESSAGE_KEY});
        } catch (error) {
            message.error({content: error.message, key: MESSAGE_KEY});
        }
        this.setState({isLoading: false});
    }

    onPressEnter(event) {
        if (event.ctrlKey) {
            this.performConversion();
        }
    }

    render() {
        const {originalContent, conversionResult, displaysResult, isLoading, deep, shorten} = this.state;

        return <Spin spinning={isLoading}>
            <Card title="Convert a paragraph" subtitle="Links in the paragraph will be converted.">
                <TextArea
                    value={originalContent}
                    onChange={event => this.setOriginalContent(event.target.value)}
                    allowClear
                    autoSize
                    onPressEnter={this.onPressEnter}
                />
                <div className={classes.actionsContainer}>
                    <Button type="primary" onClick={this.performConversion} disabled={originalContent.length === 0}>
                        <FontAwesomeIcon icon={faAngleDoubleRight} className={classes.buttonIcon}/>
                        Convert
                    </Button>
                    <Switch
                        className={classes.settingSwitch}
                        checked={deep}
                        checkedChildren="deep"
                        unCheckedChildren="plain"
                        onClick={this.toggleDeep}
                    />
                    <Switch
                        className={classes.settingSwitch}
                        checked={shorten}
                        checkedChildren="shortened"
                        unCheckedChildren="raw"
                        onClick={this.toggleShorten}
                    />
                </div>
            </Card>
            {displaysResult && <ConversionResults result={conversionResult}/>}
        </Spin>;
    }
}