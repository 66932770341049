import React from 'react';
import {Alert, Button} from 'antd';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHome} from '@fortawesome/pro-regular-svg-icons/faHome.js';

import LogoutButton from '../../../components/authentications/logoutButton/LogoutButton.jsx';

import formClasses from '../../../components/authentications/forms/AuthForm.module.less';
import classes from './AlreadyLoggedInAlert.module.less';


const AlertContent = () => {
    return <div>
        Go home or go out, you&apos;re drunk.
        <div className={classes.buttons}>
            <Link to="/">
                <Button type="primary" className={classes.buttonItem}>
                    <FontAwesomeIcon icon={faHome} className={classes.icon}/>
                    Back to safety
                </Button>
            </Link>
            <LogoutButton className={classes.buttonItem}/>
        </div>
    </div>;
};

export default function AlreadyLoggedInAlert() {
    return <div className={formClasses.form}>
        <Alert
            type="warning"
            showIcon
            message="It appears that you're already logged in."
            description={<AlertContent/>}
        />
    </div>;
}