import React from 'react';
import PropTypes from 'prop-types';
import {Link, withRouter} from 'react-router-dom';
import {Form, Button, message} from 'antd';
import {faUser} from '@fortawesome/pro-regular-svg-icons/faUser.js';
import {faAt} from '@fortawesome/pro-regular-svg-icons/faAt.js';
import {faLockAlt} from '@fortawesome/pro-regular-svg-icons/faLockAlt.js';
import {connect} from 'react-redux';

import {registerUser} from '../../../../store/ducks/user/operations.js';
import {renderFormItem, hasErrors} from '../utils.js';

import formClasses from '../AuthForm.module.less';


const FORM_FIELDS = [
    {
        name: 'displayName',
        rules: [{required: true, message: "Your name, please?"}],
        placeholder: 'Name',
        icon: faUser,
    },
    {
        name: 'email',
        rules: [{required: true, message: "What's your email address?"}],
        placeholder: 'Email Address',
        type: 'email',
        icon: faAt,
    },
    {
        name: 'password',
        rules: [{required: true, message: 'Your password cannot be empty.'}],
        placeholder: 'Password',
        type: 'password',
        icon: faLockAlt,
    },
];


class RegisterForm extends React.Component {
    static propTypes = {
        form: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            registering: false,
        };

        this.handleSubmit = ::this.handleSubmit;
        this.performRegister = ::this.performRegister;
    }

    componentDidMount() {
        this.props.form.validateFields();
    }

    handleSubmit(e) {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (err) {
                message.error(err);
            } else {
                this.performRegister(values);
            }
        });
    }

    async performRegister({displayName, email, password}) {

        this.setState({registering: true});
        try {
            await this.props.dispatch(registerUser(displayName, email, password));
            this.props.history.push('/login');
            message.success('Your account has been created! please check out your email address.');
        } catch (e) {
            message.error(e.message);
            this.setState({registering: false});
        }
    }

    render() {
        const {getFieldsError} = this.props.form;

        return <>
            <Form onSubmit={this.handleSubmit} className={formClasses.form}>
                {FORM_FIELDS.map(field => renderFormItem(this.props.form, field))}

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        className={formClasses.submitButton}
                        disabled={hasErrors(getFieldsError())}
                        loading={this.state.registering}
                    >
                        Register
                    </Button>
                    or <Link to="/login">Log in now</Link>
                </Form.Item>
            </Form>
        </>;
    }
}

const Register = Form.create()(withRouter(connect(
    null,
    null,
)(RegisterForm)));

export default Register;