import {useEffect} from 'react';
import {useParams} from 'react-router-dom';

import {API_URL} from '../../services/api';


export default function RedirectToLink() {
    const {id} = useParams();

    useEffect(() => {
        location.replace(`${API_URL}/go/${id}`);
    }, []);

    return null;
}