import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useHistory, useLocation} from 'react-router-dom';
import {Menu, Layout} from 'antd';
import _ from 'lodash';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMagic, faHome} from '@fortawesome/pro-solid-svg-icons';
import {faCog} from '@fortawesome/pro-regular-svg-icons';
import {faUserSecret} from '@fortawesome/pro-duotone-svg-icons/faUserSecret.js';

import {convertAPI} from '../../../services/api';
import FetchWrapper from '../../../components/common/fetchWrapper/FetchWrapper.jsx';

import classes from './DashboardSidebar.module.less';


const DashboardSidebar = ({isMenuOpen, closeMenu}) => {
    const menuOpenStateClassName = isMenuOpen ? classes.menuOpen : '';

    return <>
        <Layout.Sider className={`${classes.sider} ${menuOpenStateClassName}`}>
            <HomeMenu/>
            <ConversionMenu/>
            <VendorsSubmenu/>
            <SettingsMenu/>
        </Layout.Sider>
        <div className={`${classes.mobileMenuOverlay} ${menuOpenStateClassName}`} onClick={closeMenu}/>
    </>;
};

DashboardSidebar.propTypes = {
    isMenuOpen: PropTypes.bool.isRequired,
    closeMenu: PropTypes.func.isRequired,
};


export default DashboardSidebar;


const HomeMenu = () => {
    const [menuProps] = useCommonMenuProps();

    return <Menu {...menuProps}>
        <Menu.Item key="/">
            <FontAwesomeIcon icon={faHome} className={classes.navIcon}/>
            Home
        </Menu.Item>
    </Menu>;
};


const ConversionMenu = () => {
    const [menuProps, {onSubmenuTitleClick}] = useCommonMenuProps();

    return <Menu {...menuProps}>
        <Menu.SubMenu
            key="/convert"
            onTitleClick={onSubmenuTitleClick}
            title={<>
                <FontAwesomeIcon icon={faMagic} className={classes.navIcon}/>
                Convert
            </>}
        >
            <Menu.Item key="/convert/paragraph">Paragraph</Menu.Item>
            <Menu.Item key="/convert/single-link">Single Link</Menu.Item>
            <Menu.Item key="/convert/stats">Statistics</Menu.Item>
        </Menu.SubMenu>
    </Menu>;
};


const VendorsSubmenu = () => {
    const [vendors, setVendors] = useState([]);
    const [menuProps, {onSubmenuTitleClick}] = useCommonMenuProps();

    async function fetchVendors() {
        const allVendors = await convertAPI.getVendors();
        const sortedVendors = allVendors.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setVendors(sortedVendors);
    }

    return <FetchWrapper fetcher={fetchVendors}>
        <Menu {...menuProps}>
            <Menu.SubMenu
                key="/vendor"
                onTitleClick={onSubmenuTitleClick}
                title={<>
                    <FontAwesomeIcon icon={faUserSecret} className={classes.navIcon}/>
                    Vendors
                </>}
            >
                {vendors.map(vendor =>
                    <Menu.Item key={`/vendor/${vendor.slug}`}>{vendor.name}</Menu.Item>,
                )}
            </Menu.SubMenu>
        </Menu>
    </FetchWrapper>;
};


const SettingsMenu = () => {
    const [menuProps] = useCommonMenuProps();

    return <Menu {...menuProps}>
        <Menu.Item key="/settings">
            <FontAwesomeIcon icon={faCog} className={classes.navIcon}/>
            Settings
        </Menu.Item>
    </Menu>;
};


function useCommonMenuProps() {
    const [openedKeys, setOpenedKeys] = useState([]);
    const location = useLocation();
    const history = useHistory();
    const {pathname} = location;

    useEffect(() => {
        // open submenus according to pathname.
        const firstSlash = pathname.substring(0, pathname.indexOf('/', 1));
        setOpenedKeys(_.uniq([...openedKeys, firstSlash]));
    }, [pathname]);

    const onMenuSelect = selection => {
        const pathTo = selection.key;
        history.push(pathTo);
    };

    const onSubmenuTitleClick = ({key}) => {
        setOpenedKeys(_.xor(openedKeys, [key]));
    };

    const commonProps = {
        selectedKeys: [pathname],
        openKeys: openedKeys,
        onSelect: onMenuSelect,
        mode: 'inline',
        className: classes.siderMenu,
    };

    return [commonProps, {onSubmenuTitleClick}];
}
