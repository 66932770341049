import initialState from './initialState.js';
import types from './types.js';

export default function reducer(state = initialState, action) {
    switch (action.type) {
    case types.USER_LOGIN:
        return {
            ...state,
            loggedIn: true,
            user: action.user,
        };
    case types.USER_LOGOUT:
        return {
            ...state,
            loggedIn: false,
            user: initialState.user,
        };
    case types.AUTH_STATE_CHANGED:
        return {
            ...state,
            user: action.user,
            loggedIn: action.user !== null && action.user.emailVerified,
        };
    default:
        return state;
    }

}
