import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Input} from 'antd';

import Card from '../../../layout/card/Card.jsx';
import SingleLinkStat from './singleLinkStat/SingleLinkStat.jsx';
import {STAT_TYPES} from './types.js';

import classes from './ConversionResults.module.less';

const {TextArea} = Input;


export default function ConversionResults({result}) {
    const {content, stats, conversionDuration} = result;

    const hasAnyLinks = stats.length > 0;

    return <>
        <Card
            title="Conversion result"
            subtitle={`${stats.length || 'no'} ${stats.length !== 1 ? 'links were' : 'link was'} converted.`}
        >
            <TextArea autoSize value={content}/>
        </Card>

        {hasAnyLinks && <Card
            title="Conversion Analysis"
            subtitle={`Total conversion duration: ${formatDuration(conversionDuration)}`}
        >
            <div className={classes.stats}>
                {stats.map((stat, index) => {
                    return <React.Fragment key={JSON.stringify({stat, index})}>
                        <SingleLinkStat data={stat}/>
                    </React.Fragment>;
                })}
            </div>
        </Card>}
    </>;
}

ConversionResults.propTypes = {
    result: PropTypes.shape({
        content: PropTypes.string.isRequired,
        stats: PropTypes.arrayOf(STAT_TYPES),
        conversionDuration: PropTypes.number.isRequired,
    }),
};


export function formatDuration(duration) {
    return moment.duration(duration).format('m[m] s[s] S[ms]');
}
