import React, {useState} from 'react';
import {Spin} from 'antd';
import ReactMarkdown from 'react-markdown';

import FetchWrapper from '../../common/fetchWrapper/FetchWrapper.jsx';
import Card from '../../layout/card/Card.jsx';
import {settingsAPI} from '../../../services/api';

import classes from './TelegramToken.module.less';


const tokenHelp = `
Our bot [AffendorBot](https://t.me/AffendorBot) can help you spontaneously convert links in your favorite IM platform.

In order to use it, you need to authenticate using your token.
`.trim();


function TelegramToken() {
    const [token, setToken] = useState(null);
    const [isGenerating, setIsGenerating] = useState(false);


    const fetchToken = async () => {
        const result = await settingsAPI.getTelegramToken();
        setToken(result);
    };

    const generateToken = async () => {
        setIsGenerating(true);
        const result = await settingsAPI.generateTelegramToken();
        setIsGenerating(false);
        setToken(result);
    };

    return <FetchWrapper fetcher={fetchToken}>
        <Spin spinning={isGenerating}>
            <Card
                title="Telegram bot token"
                subtitle={<ReactMarkdown source={tokenHelp} linkTarget="_blank" className={classes.noP}/>}
            >
                Your token: {token}
            </Card>
            <button onClick={generateToken}>
                generate token
            </button>
        </Spin>
    </FetchWrapper>;
}


export default TelegramToken;