import React from 'react';

import ConvertParagraph from '../../conversions/convertParagraph/ConvertParagraph.jsx';

import classes from './DashboardHome.module.less';


export default function DashboardHome() {
    return <div className={classes.container}>
        <div className={classes.item}>
            <ConvertParagraph/>
        </div>
        <div className={classes.item}>
            Last conversions
        </div>
    </div>
}
