import PropTypes from 'prop-types';

export const CONVERTER_TYPE = PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
});

export const STAT_TYPES = PropTypes.shape({
    before: PropTypes.string.isRequired,
    after: PropTypes.string.isRequired,
    conversionDuration: PropTypes.number,
    converter: CONVERTER_TYPE,
    expanded: PropTypes.string,
    expansionDuration: PropTypes.number,
});