import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {Button, message, Alert} from 'antd';
import ReactMarkdown from 'react-markdown';

import FetchWrapper from '../../common/fetchWrapper/FetchWrapper.jsx';
import Card from '../../layout/card/Card.jsx';
import {vendorsAPI} from '../../../services/api';

import classes from './VendorView.module.less';


const VENDOR_MESSAGE_KEY = 'VENDOR_STUFF';


export default function VendorView() {
    const initialInfoState = {name: '...', params: {}, paramsData: {}};

    const {slug} = useParams();
    const [info, setInfo] = useState(initialInfoState);
    const [paramsData, setParamsData] = useState({});
    const [lastFetched, setLastFetched] = useState(new Date());
    const [isSaving, setIsSaving] = useState(false);


    const fetchInfo = async () => {
        const response = await vendorsAPI.getVendor(slug);
        setInfo(response);

        const paramValues = {};
        Object.keys(response.params).map(param => paramValues[param] = response.paramsData[param] || '');
        setParamsData(paramValues);
    };

    const updateParamData = paramPair => setParamsData({...paramsData, ...paramPair});

    const saveUserVendorParams = async () => {
        setIsSaving(true);
        try {
            await vendorsAPI.saveVendor(slug, paramsData);
            message.success({content: 'Successfully saved parameters.', key: VENDOR_MESSAGE_KEY});
            setLastFetched(new Date());
        } catch (e) {
            message.error({content: e.response.data, key: VENDOR_MESSAGE_KEY});
        }
        setIsSaving(false);
    };


    const {params, name, slug: slugValue, paramsData: paramsDataInDatabase} = info;
    const hasEmptyValuesInDatabase = Object.values(paramsDataInDatabase).filter(val => val.length === 0).length > 0;

    const hasEmptyValues = Object.values(paramsData).filter(val => val.length === 0).length > 0;
    const didChangedValues = Object.keys(paramsData).some(param => paramsData[param] !== paramsDataInDatabase[param]);

    return <FetchWrapper fetcher={fetchInfo} trigger={[slug, lastFetched]}>
        <Card title={name} subtitle={`Slug: ${slugValue}`} loading={isSaving}>
            {hasEmptyValuesInDatabase && <Alert
                message="Some of your values are empty"
                description="We won't be able to convert links from this vendor until you fix that."
                type="error"
                showIcon
            />}

            <div className={classes.vendorParamContainer}>
                {Object.keys(params).map(param =>
                    <VendorParam
                        key={param}
                        param={param}
                        info={params[param]}
                        value={paramsData[param]}
                        updateValue={updateParamData}
                    />
                )}
            </div>
        </Card>

        <Button
            disabled={hasEmptyValues || !didChangedValues}
            onClick={saveUserVendorParams}
            type="primary"
            className={classes.saveBtn}
        >Save</Button>
    </FetchWrapper>;
}


function VendorParam({param, info, value = '', updateValue}) {
    const {name, description} = info;

    const showEmptyError = value.length === 0;

    return <div className={classes.vendorParam}>
        <Card title={name} subtitle={<ReactMarkdown source={description} linkTarget="_blank"/>}>
            <input
                type="text"
                placeholder={param}
                value={value}
                onChange={event => updateValue({[param]: event.target.value})}
                className={`${classes.input} ${showEmptyError ? classes.inputEmptyError : ''}`}
            />
            {showEmptyError && <div className={classes.emptyError}>Value cannot be empty.</div>}
        </Card>
    </div>;
}

VendorParam.propTypes = {
    param: PropTypes.string,
    info: PropTypes.shape({
        name: PropTypes.string,
        description: PropTypes.string,
    }).isRequired,
    value: PropTypes.string.isRequired,
    updateValue: PropTypes.func.isRequired,
};
