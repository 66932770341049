import {API} from './index.js';

export const getTelegramToken = async () => {
    const result = await API.get('/settings/telegramBot/token');
    return result.data;
};

export const generateTelegramToken = async () => {
    const result = await API.get('/settings/telegramBot/newToken');
    return result.data;
};