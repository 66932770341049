import firebaseService from '../../../services/firebaseService.js';
import firebase from 'firebase/app';

import {userLogin, userLogout} from './actions.js';
import {EmailNotVerifiedException} from './exceptions.js';


export const loginUser = (email, password, remember) => async dispatch => {
    firebaseService.auth().setPersistence(
        remember ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION
    );

    const result = await firebaseService.auth().signInWithEmailAndPassword(email, password);

    if (!result.user.emailVerified) {
        firebaseService.auth().signOut();
        throw new EmailNotVerifiedException({email, password});
    }

    dispatch(userLogin(result.user));
};

export const logoutUser = () => async dispatch => {
    await firebaseService.auth().signOut();
    dispatch(userLogout());
};

export const registerUser = (displayName, email, password) => async dispatch => {
    const {user} = await firebaseService.auth().createUserWithEmailAndPassword(email, password);
    user.sendEmailVerification();
    user.updateProfile({
        displayName,
    });
    firebaseService.auth().signOut();
};

export const requestEmailVerification = (email, password) => async dispatch => {
    const {user} = await firebaseService.auth().signInWithEmailAndPassword(email, password);
    if (!user.emailVerified) {
        await user.sendEmailVerification();
        firebaseService.auth().signOut();
    } else {
        throw new Error('Your email is already verified. Nothing to do :)');
    }
};

export default {
    loginUser,
    logoutUser,
    registerUser,
    requestEmailVerification,
};