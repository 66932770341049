import React from 'react';

import TelegramToken from '../TelegramToken/TelegramToken.jsx';


function SettingsView() {
    return <>
        <TelegramToken/>
    </>;
}


export default SettingsView;