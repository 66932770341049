exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".FetchWrapper-module--spinnerIndicator___107ax {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n}\n.FetchWrapper-module--tooltipMessage___3UIzB {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.FetchWrapper-module--tooltipMessage___3UIzB .FetchWrapper-module--messageContent___3QcNe {\n  font-size: 0.875em;\n}\n.FetchWrapper-module--tooltipMessage___3UIzB .FetchWrapper-module--retry___XNpft {\n  cursor: pointer;\n  user-select: none;\n  margin-top: 0.25em;\n  font-size: 0.75em;\n  display: flex;\n  align-items: center;\n  opacity: 0.5;\n  transition: opacity 0.2s;\n}\n.FetchWrapper-module--tooltipMessage___3UIzB .FetchWrapper-module--retry___XNpft:hover {\n  opacity: 1;\n}\n.FetchWrapper-module--tooltipMessage___3UIzB .FetchWrapper-module--retry___XNpft .FetchWrapper-module--retryLabel___3KR3T {\n  margin-inline-start: 1ch;\n}\n", ""]);
// Exports
exports.locals = {
	"spinnerIndicator": "FetchWrapper-module--spinnerIndicator___107ax",
	"tooltipMessage": "FetchWrapper-module--tooltipMessage___3UIzB",
	"messageContent": "FetchWrapper-module--messageContent___3QcNe",
	"retry": "FetchWrapper-module--retry___XNpft",
	"retryLabel": "FetchWrapper-module--retryLabel___3KR3T"
};