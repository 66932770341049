import React from 'react';
import PropTypes from 'prop-types';
import {Spin} from 'antd';

import classes from './Card.module.less';
import {LoadingIndicator} from '../../common/fetchWrapper/FetchWrapper.jsx';


export default function Card({title, subtitle, loading, children, ...props}) {
    const classNames = [classes.card];
    props.className && classNames.push(props.className);

    return <Spin spinning={loading} indicator={<LoadingIndicator/>} wrapperClassName={classes.cardContainer}>
        <div className={classNames.join(' ')}>
            {title && <CardTitle subtitle={subtitle}>{title}</CardTitle>}
            <div className={classes.content}>
                {children}
            </div>
        </div>
    </Spin>;
}

Card.propTypes = {
    title: PropTypes.node,
    subtitle: PropTypes.node,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    loading: PropTypes.bool,
};

Card.defaultProps = {
    loading: false,
};


function CardTitle({subtitle, children}) {
    return <div className={classes.titleContainer}>
        <div className={classes.title}>{children}</div>
        {subtitle && <div className={classes.subtitle}>{subtitle}</div>}
    </div>
}

CardTitle.propTypes = {
    subtitle: PropTypes.node,
    children: PropTypes.node,
};
